/* eslint-disable */
// @ts-nocheck
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// import { ReactReduxContext } from 'config/configureStore';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { actionProps, selectState } from './reduxActions';

// export const IntlCLP = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });
export const IntlNumeric = new Intl.NumberFormat('es-CL');

export const currencyForm = new Intl.NumberFormat('es-CL', { currency: 'CLP', style: 'currency' });
export const LOT_CROP_RATIO = 1.3063;

export const isNotANumber = (value) => String(value) === 'NaN';

/**
 * It will check if a value is falsy but with slightly modifications.
 * @example
 * | type    |  description                    |
 * |---------|–--------------------------------|
 * | Objects | "{}" => true. "{a: 2}" => false |
 * |---------|---------------------------------|
 * | Arrays  | "[]" => true. "[2]" => false    |
 * |---------|---------------------------------|
 *
 * @param {Any} value Any value to be checked
 * @return Boolean
 */
export const falsy = (value) => {
  let isFalsy = false;

  function isPromise(object) {
    if (Promise && Promise.resolve) {
      return Promise.resolve(object) === object;
    }
  }

  if (!isPromise(value) && !value) {
    isFalsy = true;
  } else if (!isPromise(value) && typeof value === 'object') {
    if (!(value instanceof Date)) {
      // check for dates
      isFalsy = !Object.keys(value).length;
    }
  }

  return isFalsy;
};

/**
 * It will check if a value is truthty but with slightly modifications.
 * @example
 * | type    |  description                    |
 * |---------|–--------------------------------|
 * | Objects | "{}" => false. "{a: 2}" => true |
 * |---------|---------------------------------|
 * | Arrays  | "[]" => false. "[2]" => true    |
 * |---------|---------------------------------|
 *
 * @param {Any} value Any value to be checked
 * @return Boolean
 */
export const truthty = (value) => {
  let isTruthy = false;

  if (/^\d+$/.test(value)) {
    value = Number(value);
  }

  if (value && !isNotANumber(value)) {
    isTruthy = true;
    if (typeof value === 'object' && Object.keys(value).length) {
      isTruthy = true;
    } else if (typeof value === 'object' && !Object.keys(value).length) {
      isTruthy = false;
    }
  }

  return isTruthy;
};

Object.defineProperty(Object, 'extractValues', {
  /**
   * It will return all the values from an Object or an alement from an specific
   * given position.
   *
   * @param {Object} element The object to extract all the values
   * @param {Number} position The position of the element we would like to get in return
   * @return Array of the object values.
   */
  value: function extractValues(element, position) {
    let el = [];

    if (truthty(element) && Object.values(element).length) {
      if (position >= 0 && position !== undefined && position !== null) {
        el = Object.values(element)[position];
      } else {
        el = Object.values(element);
      }
    }

    return el;
  },
  writable: true,
});

Object.defineProperty(Object, 'hasValues', {
  /**
   * It will check if the given object has any elements as a value
   *
   * @param {Object} element The element to check the values
   * @return Boolean
   */
  value: function hasValues(element) {
    return Boolean(Object.values(element).length);
  },
  writable: true,
});

Object.defineProperty(Object, 'findValue', {
  value: function findValue(element, fnc) {
    return truthty(element) ? Object.values(element).find(fnc) : [];
  },
  writable: true,
});

Object.defineProperty(Object, 'filterValues', {
  value: function filterValues(element, fnc) {
    return truthty(element) ? Object.values(element).filter(fnc) : [];
  },
  writable: true,
});

/**
 * It will turn out an object into an array of objects keeping the original
 * keys
 *
 * @param {object} obj the object to seek for
 * @return {array} An array of objects
 */
export const objectToArray = (obj) =>
  Object.keys(obj).reduce((prev, current) => [...prev, { [current]: obj[current] }], []);

/**
 * It will turn out an array of object into a new array of objects or a new object of objects, but
 * the objects in the arrays are based on the passed `key` and `value` params
 * extracted from the objects in the initial array
 *
 * @param {array} array The array to seek for
 * @param {string} key The name of the atribute to use as a key
 * @param {string} value The name of the atribute to use as a value
 * @return {array} a new array of objects
 */
export const compressObject = (array, key, value) =>
  array.reduce((prev, current) => [...prev, { [current[key]]: current[value] }], []);

export const appendToObject = (array, extraKey, extraValue, objItself = false) =>
  array.reduce(
    (prev, current) => [
      ...prev,
      {
        ...current,
        [extraKey]: objItself ? current[extraValue] : extraValue,
      },
    ],
    [],
  );

/**
 * It will turn out an array of objects to a single object
 *
 * @param {array} arr The array to seek for
 * @return {object} A new object
 */
export const flatObject = (arr) =>
  arr.reduce((prev, current) => {
    const [[key, value]] = Object.entries(current);

    prev[key] = value;
    return prev;
  }, {});

/**
 * HOC - It will return only one value from an object.
 * falsy values are not returned.
 * Eg: data.name - returned value is "name".
 * Useful to work with `map`
 *
 * @param {any} key The name of the chain attribute to get the value from
 * @param {any} content The content from where to extract the value
 * @return {any} The needed value
 */
export const uniqueObjValue = (key) => (content) => content[key];

export const setInStorage = (name, data, storeType) =>
  (storeType === 'session' ? sessionStorage : localStorage).setItem(name, JSON.stringify(data));

export const getItemInStorage = (name, storeType) =>
  JSON.parse((storeType === 'session' ? sessionStorage : localStorage).getItem(name));

export const removeItemInStorage = (name, storeType) =>
  (storeType === 'session' ? sessionStorage : localStorage).removeItem(name);

export const normalizeObj = (arr) =>
  arr.reduce((prev, current) => {
    prev[current.id] = current;
    return prev;
  }, {});

export const getObjectByKey = (element) => (key) => ({
  [key]: element[key],
});

/**
 * It will concat and execute several functions synchronously.
 * If you add more than one value, only the first function will recive them and
 * the result of it will be passed down through the rest of the functions
 *
 * @param {function} func - Functions.
 * @return {function} - The result of passing all the values through
 *               the functions.
 */
export const pipe = (...func) =>
  func.reduce(
    (prevFunc, currentFunc) =>
      (...values) =>
        currentFunc(prevFunc(...values)),
  );

export const removeKeyByFilter = (object, keyParam) =>
  Object.keys(object)
    .filter((key) => key !== String(keyParam))
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

/**
 *
 * @param {object} object
 * @param {Array} keyParams list of keys to delete
 */
export const removeManyKeysByFilter = (object, keyParams) =>
  Object.keys(object)
    .filter((key) => keyParams.indexOf(Number(key)) === -1)
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});

export const formatNumber = (number) => {
  number = number ? number.toString().split('.') : 0;
  const format = number[0] ? number[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : 0;
  const decimal = number[1] ? number[1].toString() : 0;
  return decimal ? format + ',' + decimal : format;
};

export const formatNumberDollar = (num) => {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
};

export const invertObj = (obj) =>
  Object.entries(obj).reduce((prev, current) => {
    const [key, value] = current;
    prev[value] = key;
    return prev;
  }, {});

// ------------------
export const clone = (objectType, ...obj) =>
  objectType === 'OBJECT'
    ? Object.assign({}, ...obj)
    : obj.reduce((prev, current) => prev.concat(current), []);

Object.defineProperty(clone, 'ARRAY', {
  value: 'ARRAY',
  writable: true,
});

Object.defineProperty(clone, 'OBJECT', {
  value: 'OBJECT',
  writable: true,
});

const regex = new RegExp('\\.\\s*\\w{1}', 'g');
export const humanizeText = (text, fullname = false) => {
  let tempText = '';
  if (truthty(text)) {
    tempText = text.toLowerCase();

    if (fullname) {
      tempText = tempText
        .split(/\s+/)
        .map((a) => `${a[0].toUpperCase()}${a.slice(1)}`)
        .join(' ');
    } else {
      tempText = `${tempText[0].toUpperCase()}${tempText.slice(1)}`;

      while (regex.exec(tempText) !== null) {
        tempText = `${tempText.slice(0, regex.lastIndex - 2)}${tempText[
          regex.lastIndex - 1
        ].toUpperCase()}${tempText.slice(regex.lastIndex)}`;
      }
    }
  }

  return tempText;
};

export const cleanString = (str) =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ñ/g, 'n');

/**
 * HOF that will check if one of the values is equal to the given one.
 * Works only with single params.
 *
 * @example
 * [1,2,3].filter(hasValue(2));
 *
 * @param {any} value Any type of value
 * @param {function} content
 */
export const hasValue = (value) => (content) => content === value;

/**
 * HOF that will check if one of the values is different to the given one.
 * Works only with single params.
 *
 * @example
 * [1,2,3].filter(hasNotValue(2));
 *
 * @param {any} value Any type of value
 * @param {function}
 */
export const hasNotValue = (value) => (content) => content !== value;

/**
 * HOF that will check if one of the values is equal to the given one.
 *
 * @example
 * [{id: 1}, {id: 2}, {id: 3}].filter(hasValueByKey('id', 3));
 *
 * @param {String} key the key of the object
 * @param {Any} value the value to match against the value of the object found by the given key.
 * @return true
 */
export const hasValueByKey = (key, value) => (content) => content[key] === value;

/**
 * HOF that will check if within the array are not the given value.
 *
 * @example
 * [{id: 1}, {id: 2}, {id: 3}].filter(hasNotValueByKey('id', 3));
 *
 * @param {String} key the key of the object
 * @param {Any} value the value to match against the value of the object found by the given key.
 * @return false
 */
export const hasNotValueByKey = (key, value) => (content) => content[key] !== value;

export const hasEveryValue = (element, values) => {
  let bool = false;

  for (let index = 0, size = values.length; index < size; index += 1) {
    bool = values === element;
  }

  return bool;
};

export const hasNotEveryValue = (element, values) => {
  let bool = false;

  for (let index = 0, size = values.length; index < size; index += 1) {
    bool = values !== element;
  }

  return bool;
};

/**
 * It will check a single value against N values. If at least one value is true
 * it will stop checking and return true, otherwise false
 *
 * @param {Any} element The element to match against with.
 * @param {Array} values All the values to match
 * @return Boolean
 */
export const hasSomeValue = (element, values) => values.indexOf(element) !== -1;

/**
 * It will check a single value against N values. If at least one value is false
 * it will stop checking and return true, otherwise false
 *
 * @param {Any} element The element to match against with.
 * @param {Array} values All the values to match
 * @return Boolean
 */
export const hasNotSomeValue = (element, values) => values.indexOf(element) === -1;

export const hasSomeValues = (arr, values) => {
  let bool = false;

  for (let index = 0, size = arr.length; index < size; index += 1) {
    if (values.indexOf(arr[index]) !== -1) {
      bool = true;
      break;
    }
  }

  return bool;
};

export const someValuesByKey = (key, values) => (content) => values.indexOf(content[key]) !== -1;

export const notEveryValueByKey = (key, values) => {
  let bool = false;
  return (content) => {
    for (let index = 0, size = values.length; index < size; index += 1) {
      bool = values[index] !== content[key];
    }

    return bool;
  };
};

/**
 * It will cancel the bubble execution.
 *
 * @param {Object} event the event object from any synthetic event
 * @return false
 */
export const cancelEvent = (event) => {
  event.stopPropagation();
  event.preventDefault();
  event.returnValue = false;
  event.cancelBubble = true;
  return false;
};

/**
 * It will create a single string of valid classes
 *
 * @example
 * appendClasses(['px-0 border-0', props.className, props.anotherClass && 'text-right']);
 *
 * @param {array} classes A list of string classes.
 * @return The joined classes. Eg.'px-0 border-0'
 */
export const appendClasses = (classes) =>
  classes.filter((cls) => typeof cls === 'string' && cls).join(' ');

/**
 * Object with amount of objects with each month
 *
 */
export const amountLinesOfDate = (object) =>
  object !== undefined
    ? Object.values(object)
        .map(uniqueObjValue('date'))
        .reduce((prev, current) => {
          const year = current.split('-')[0];
          return {
            ...prev,
            [year]: {
              amount: Number(prev[year] ? prev[year].amount : 0) + 1,
              date: year,
            },
          };
        }, {})
    : [];

export const amountLinesOfYear = (object) => {
  const years = [...new Set(Object.values(object).map((item) => item.year))];
  const values = Object.values(object);

  return years.reduce(
    (prev, current, index) => ({
      ...prev,
      [index * 2]: {
        amount: values.filter((item) => item.year === current && item.origin === 'USA').length,
        origin: 'USA',
        date: current,
      },
      [index * 2 + 1]: {
        amount: values.filter((item) => item.year === current && item.origin === 'Chile').length,
        origin: 'Chile',
        date: current,
      },
    }),
    {},
  );
};

export const formatRut = (Rut, digitoVerificador) => {
  if (Rut) {
    var sRut = Rut.toString();
    var sRutFormateado = '';

    sRut = unformat(sRut);
    if (digitoVerificador) {
      var sDV = sRut.charAt(sRut.length - 1);
      sRut = sRut.substring(0, sRut.length - 1);
    }
    while (sRut.length > 3) {
      sRutFormateado = '.' + sRut.substr(sRut.length - 3) + sRutFormateado;
      sRut = sRut.substring(0, sRut.length - 3);
    }
    sRutFormateado = sRut + sRutFormateado;
    if (sRutFormateado !== '' && digitoVerificador) {
      sRutFormateado += '-' + sDV;
    } else if (digitoVerificador) {
      sRutFormateado += sDV;
    }

    return sRutFormateado;
  }
  return '';
};

export const unformat = (rut) => {
  var strRut = rut.toString();

  while (strRut.indexOf('.') !== -1) {
    strRut = strRut.replace('.', '');
  }
  while (strRut.indexOf('-') !== -1) {
    strRut = strRut.replace('-', '');
  }

  return strRut;
};

export const fCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const fNumericDate = new Intl.DateTimeFormat('es-CL', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'UTC',
});
export const formatDate = (date) =>
  date.toLocaleDateString('es-ES', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const onChangeFnc = (onChange, event, date, withModule) => {
  if (event.persist) {
    event.persist();
  }
  let value;
  switch (event.target.type) {
    case 'date':
      value = date;
      break;
    case 'time':
      value = date;
      break;
    case 'checkbox':
      value = event.target.checked;
      break;
    default:
      value = event.target.value;
      break;
  }
  const id = event.target.id || event.currentTarget.id || event.target.name;
  if (withModule) {
    onChange(event.currentTarget.dataset.module, { [id]: value }, { [id]: false });
  } else {
    onChange({ [id]: value }, { [id]: false });
  }
};

export const getParameterByName = (name, url) => {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const convertVideo = (input) => {
  let pattern = '';
  if (input.includes('youtube') || input.includes('youtu.be')) {
    pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
    if (pattern.test(input)) {
      if (input.includes('/embed')) {
        return input;
      }
      let replacement = 'https://www.youtube.com/embed/$1';
      return input.replace(pattern, replacement);
    }
  } else if (input.includes('vimeo')) {
    pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
    if (pattern.test(input)) {
      if (input.includes('https://player')) {
        return input;
      }
      let replacement = 'https://player.vimeo.com/video/$1';
      return input.replace(pattern, replacement);
    }
  }
  return null;
};

export const formatCLP = (value) => {
  return '$' + IntlNumeric.format(value).toString();
};

export const formatFileName = (value) => {
  return value.slice(0, -4);
};

export const formatNumeric = (value) => IntlNumeric.format(value);

export const findText = (searchTerm, text) => new RegExp(searchTerm, 'i').test(text);

export const getUserPermit = (user) => {
  let permit;
  if (!user) {
    permit = 'none';
  } else if (user.roleId === 1) {
    permit = 'administrator';
  } else if (user.roleId === 2) {
    permit = 'supervisor';
  } else if (user.areaId) {
    permit = 'tattersallUser';
  } else {
    permit = 'normalUser';
  }
  return permit;
};

export function momentDateIsBeforeToday(date) {
  return moment(date).isBefore(moment().hour(0).minute(0).second(0));
}

export function sortMomentStringsDescending(first, other) {
  return moment(other).unix() - moment(first).unix();
}

export function sortMomentStringsAscending(first, other) {
  return moment(first).unix() - moment(other).unix();
}

export function validate(controls, defaultInvalidControls, actions) {
  const invalidControls = defaultInvalidControls.reduce((acum, key) => {
    if (!controls[key]) {
      acum[key] = true;
    }
    return acum;
  }, {});
  if (falsy(invalidControls)) {
    return true;
  } else {
    actions.controlsChanged({}, invalidControls);
    return false;
  }
}

export function composedComponent(component, saga, options) {
  // const withSaga = connectSaga({ key: options.saga, saga });
  const actions = [...options.actions];
  const withConnect = connect(
    options.selector ?? selectState(...(options.states ?? [])),
    actionProps(actions.reduce((r, c) => Object.assign(r, c), {})),
    null,
    // { context: ReactReduxContext },
  );
  return compose(...(options.middlewares ?? []), withRouter, withConnect)(component);
}

export function mapOptions(prefix, options) {
  return options.map((option, index) => {
    return (
      <option key={`${prefix}-${index}`} value={index}>
        {option}
      </option>
    );
  });
}

export function unformatParse(value) {
  if (typeof value === 'number' || value == null) {
    return value;
  }
  const unformattedValue = value.toString().replace(/[.]/g, '').replace(',', '.');
  const isEmpty = unformattedValue.trim().length === 0;
  return isEmpty ? 0 : Number(unformattedValue);
}

export function unformatNumber(value) {
  if (value == null) {
    return null;
  }
  return value.toString().replace(/[.]/g, '').replace(',', '.');
}

export function round(value, places) {
  return +(Math.round(value + 'e+' + places) + 'e-' + places);
}

export function base64ToImageAsync(base64) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.src = 'data:image/jpeg;base64, ' + base64;
    image.id = `photo-${Math.random() * 10}`;
  });
}
export function base64ToImage(base64String) {
  // Crear una nueva instancia de Image
  var image = new Image();

  // Añadir el prefijo necesario para especificar el formato de la imagen en base64
  image.src = 'data:image/jpeg;base64,' + base64String;
  image.id = `photo-${Math.random() * 10}`;

  return image;
}

export function getCroppedImage(image, crop, index) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.imageSmoothingEnabled = false;

  canvas.width = crop.width;
  canvas.height = crop.height;

  context.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);
  const data = canvas.toDataURL();
  const resultImage = new Image();
  resultImage.src = data;
  resultImage.id = `photo-${Math.random() * 10}`;
  return resultImage;
}

export function imageToCanvas(image) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.imageSmoothingEnabled = false;

  canvas.width = image.width;
  canvas.height = image.height;

  context.drawImage(image, 0, 0);

  const data = canvas.toDataURL();
  const resultImage = new Image();
  resultImage.src = data;
  return resultImage;
}

export function imageToBlobAsync(image) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.imageSmoothingEnabled = false;

    canvas.width = image.width;
    canvas.height = image.height;

    context.drawImage(image, 0, 0);

    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.9);
  });
}

export function blobToFile(blob, filename) {
  return new File([blob], filename, { type: 'image/jpeg' });
}

export function getDefaultCrop(image, defaultRatio) {
  const width = image.width;
  const height = image.height;
  const relationAspect = width / height;
  let crop = { x: 0, y: 0, width, height };

  if (relationAspect >= defaultRatio) {
    crop.width = defaultRatio * crop.height;
    crop.x = width / 2 - crop.width / 2;
  } else {
    crop.height = crop.width / defaultRatio;
    crop.y = height / 2 - crop.height / 2;
  }
  return crop;
}

export function verifierDigit(rut) {
  var M = 0,
    S = 1;
  for (; rut; rut = Math.floor(rut / 10)) S = (S + (rut % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
}

export function validateTaxNumber(rut) {
  if (rut == null) {
    return false;
  }
  const rutCompleto = rut.toString()?.replace(/[-.]/g, '').trim();
  if (!/^[0-9]+[0-9kK]{1}$/.test(rutCompleto)) return false;
  let digv = rutCompleto.slice(-1);
  const rutSinDv = rutCompleto.slice(0, -1);
  if (digv === 'K') digv = 'k';
  return verifierDigit(rutSinDv).toString() === digv.toString();
}

export function validateEmail(valor) {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/.test(valor);
}

export function deconstructEvent(event, date) {
  if (event.persist) {
    event.persist();
  }
  let value;
  if (event.target.type === 'file') {
    value = event.target.files[0];
  } else if (event.target.type === 'checkbox') {
    value = event.target.checked;
  } else {
    value =
      event.target.type === 'date' || event.target.type === 'time' ? date : event.target.value;
  }
  const id = event.target.type ? event.target.id : event.currentTarget.id;
  return { id, value };
}

export function isAdmin(user) {
  return user != null && user.roleId === 1;
}

export function isComercial(user) {
  return user != null && (user.roleId === 1 || user.roleId === 4);
}

export function isFinance(user) {
  return user != null && (user.roleId === 1 || user.roleId === 3);
}

export function isTattersall(user) {
  return user?.roleId != null;
}

export function mapSyncFlag(flag) {
  switch (flag.toString()) {
    case '10':
      return 'No constituida';
    case '11':
      return 'Cliente';
    case '12':
      return 'Mandante';
    case '13':
      return 'Interno';
    case '14':
      return 'Observador';
    default:
      return 'Marcada';
  }
}

const RoleDefinition = {
  NONE: 0,
  CLIENT: 1 << 0,
  COMERCIAL: 1 << 1,
  FINANCES: 1 << 2,
  OPERATIONS: 1 << 3,
  ADMIN: 1 << 4,
  SUPERADMIN: 1 << 5,
};

export const Roles = Object.freeze({
  ...RoleDefinition,
  CONTACT: RoleDefinition.SUPERADMIN | RoleDefinition.ADMIN | RoleDefinition.COMERCIAL,
  FINANCIAL: RoleDefinition.SUPERADMIN | RoleDefinition.ADMIN | RoleDefinition.FINANCES,
  MANAGEMENT:
    RoleDefinition.SUPERADMIN |
    RoleDefinition.ADMIN |
    RoleDefinition.COMERCIAL |
    RoleDefinition.OPERATIONS,
  TATTERSALL:
    RoleDefinition.SUPERADMIN |
    RoleDefinition.ADMIN |
    RoleDefinition.COMERCIAL |
    RoleDefinition.FINANCES |
    RoleDefinition.OPERATIONS,
  ANY:
    RoleDefinition.SUPERADMIN |
    RoleDefinition.ADMIN |
    RoleDefinition.COMERCIAL |
    RoleDefinition.FINANCES |
    RoleDefinition.OPERATIONS |
    RoleDefinition.CLIENT,
});

export function mapRoleIdToFlag(roleId) {
  switch (roleId) {
    case null:
      return Roles.CLIENT;
    case 1:
      return Roles.ADMIN;
    case 2:
      return Roles.OPERATIONS;
    case 3:
      return Roles.FINANCES;
    case 4:
      return Roles.COMERCIAL;
    case 6:
      return Roles.SUPERADMIN;
    default:
      return 0;
  }
}

/**
 * @param {number} roleId
 * @param {Roles} roles
 */
export function checkRole(roleId, roles) {
  const roleFlag = mapRoleIdToFlag(roleId);
  const isRole = roles & roleFlag;
  return Boolean(isRole);
}

export function removeNullValues(obj, removeMinusOne = false) {
  if (!obj) return {};
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] == null || (removeMinusOne && obj[key].toString() === '-1')) {
      return result;
    }
    result[key] = obj[key];
    return result;
  }, {});
}

export function asQuery(obj, removeMinusOne = false) {
  return new URLSearchParams(removeNullValues(obj, removeMinusOne));
}

export function m2ToHa(area) {
  return area / 10000;
}

export function withIds(objects, ids) {
  return (ids ?? []).map((id) => objects[id]);
}

export function today() {
  return moment().hour(0).minute(0).second(0).millisecond(0);
}

export function extension(filename) {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(filename)[1];
}

export const compareSimpleObject = (objectA, objectB) => {
  const keysA = Object.keys(objectA);
  const keysB = Object.keys(objectB);
  if (keysA.length !== keysB.length) return false;
  const compareA = keysA.some((key) => {
    return objectB[key] === undefined || objectB[key]?.toString() !== objectA[key]?.toString();
  });

  if (compareA) return false;

  const compareB = keysB.some((key) => {
    return objectA[key] === undefined || objectB[key]?.toString() !== objectA[key]?.toString();
  });

  return !compareB;
};

export const onlyKeysWithValue = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      result[key] = obj[key];
    }
  });
  return result;
};

export const getUrlLotsParams = (params) => ({
  areaId: params.get('areaId') || '',
  auctionId: params.get('auctionId') || '',
  auctionCategoryId: params.get('auctionCategoryId') || '',
  lotType: params.get('lotType') || '',
  auctionImportId: params.get('auctionImportId') || '',
  searchParaments: params.get('searchParaments') || '',
  page: params.get('page'),
  offset: params.get('offset') || '',
  limit: params.get('limit') || '',
  lotCategoryId: params.get('lotCategoryId') || '',
  stateId: params.get('stateId') || '',
  minArea: params.get('minArea') || '',
  maxArea: params.get('maxArea') || '',
  minUtilArea: params.get('minUtilArea') || '',
  maxUtilArea: params.get('maxUtilArea') || '',
  regionId: params.get('regionId') || '',
  communeId: params.get('communeId') || '',
  minRange: params.get('minRange') || '',
  maxRange: params.get('maxRange') || '',
  unitId: params.get('unitId') || '',
});

export const getUrlDirectSalesFeaturedParams = (params) => ({
  page: params.get('page'),
  offset: params.get('offset') || '',
  limit: params.get('limit') || '',
  categoryId: params.get('categoryId') || '',
  brandId: params.get('brandId') || '',
  modelId: params.get('modelId') || '',
});

export const basicUrlPagination = '&offset=0&page=0&limit=12&unitId=0&minRange=0&maxRange=0'; // aregar valores por defecto
// export const basicUrlPagination = '&offset=0&page=0&limit=12'; // aregar valores por defecto
export const limitGuaranteeFiles = 15;

export const childrenFromObject = (originObj, keysToExtract) => {
  const keys = Array.isArray(keysToExtract) ? keysToExtract : Object.values(keysToExtract);
  const objCopy = { ...originObj };
  const result = {};
  keys.forEach((key) => (result[key] = objCopy[key]));
  return result;
};

export const createOptions = (arr, name, allowNull = true) => {
  const options = [];
  if (allowNull) {
    options.push(<option key={`${name}-0`} value={null} />);
  }
  options.push(
    ...arr.map((item) => (
      <option key={`${name}-${item.value}`} value={item.value}>
        {item.label}
      </option>
    )),
  );

  return options;
};

export const transformPriceToGuarantee = (mainId, resultId, price, units) => {
  const initialPrice = (10 / 100) * Number(price);
  const mainFactor = units[mainId].factor;
  const secondaryFactor = units[resultId].factor;
  const priceInCLP = Number(initialPrice) * Number(mainFactor);
  return Math.round((priceInCLP / secondaryFactor) * 100) / 100;
};

export const handleCopyToClipboard = (url, data) => {
  const host = window.location.host;
  const toUrl = `${host + url}`;
  const toCopy = url ? toUrl : data;
  navigator.clipboard.writeText(toCopy);
  return 'Copiado al portapeles!';
};

export const handleWhatsappShare = (url, type, title) => {
  const dataType = type === 'lot' ? 'LOTE' : 'REMATE';
  const host = window.location.host;
  const destinedUrl = encodeURIComponent(host + url);
  const message = encodeURI(`TATTERSALL - ${dataType} : ${title}, ENLACE: `);
  const toUrl = `https://wa.me/?text=${message + destinedUrl}`;
  window.open(toUrl, '_blank', 'noopener,noreferrer');
};

export const handleFbShare = (url) => {
  const host = window.location.host;
  const lotLink = encodeURIComponent(host + url);
  const destinedUrl = `https://www.facebook.com/sharer/sharer.php?u=${lotLink}`;
  window.open(destinedUrl, '_blank', 'noopener,noreferrer');
};

/**
 * Returns the name of auction by auction type code
 * @param {string} code
 * @param {number} areaId
 * @returns {{main, live, bases}} Sum of a and b
 */
export const getAuctionTypeName = (code, areaId = 1) => {
  let bases;
  switch (code) {
    case 'BID':
      bases = {
        1: {
          main: 'licitación',
          live: 'a la licitación',
          bases: 'de la licitación',
        },
        2: {
          main: 'licitación',
          live: 'a la licitación',
          bases: 'de la licitación',
        },
      };
      break;
    case 'LEA':
      bases = {
        1: {
          main: 'arriendo',
          live: 'al arriendo',
          bases: 'del arriendo',
        },
        2: {
          main: 'arriendo',
          live: 'al arriendo',
          bases: 'del arriendo',
        },
      };
      break;
    default:
      bases = {
        1: {
          main: 'remate',
          live: 'al remate',
          bases: 'del remate',
        },
        2: {
          main: 'remate',
          live: 'al remate',
          bases: 'del remate',
        },
      };
  }

  return bases[areaId];
};

/**
 * Returns SAT integrations values
 * @param {object} controls
 * @returns { { lines, description } } SAT formatted data
 */
export const formatSATData = (controls) => {
  let lines = [
    { label: 'Marca', value: controls.satIntegrationData.marca, typeCode: 'inline' },
    { label: 'Modelo', value: controls.satIntegrationData.modelo, typeCode: 'inline' },
    { label: 'Año', value: controls.satIntegrationData.anio, typeCode: 'inline' },
    { label: 'Patente', value: controls.satIntegrationData.patente, typeCode: 'inline' },
    {
      label: 'Permiso de circulación',
      value: controls.satIntegrationData.pcirluacion,
      typeCode: 'inline',
    },
    {
      label: 'Seguro Obligatorio',
      value: controls.satIntegrationData.seguroobl,
      typeCode: 'inline',
    },
    {
      label: 'Revision Tecnica',
      value: controls.satIntegrationData.revisiontec,
      typeCode: 'inline',
    },
    { label: 'Aire', value: controls.satIntegrationData.aire, typeCode: 'inline' },
    { label: 'Caja', value: controls.satIntegrationData.caja, typeCode: 'inline' },
    { label: 'Motor', value: controls.satIntegrationData.motor, typeCode: 'inline' },
    { label: 'Kilometraje', value: controls.satIntegrationData.kilometraje, typeCode: 'inline' },
    { label: 'Color', value: controls.satIntegrationData.color, typeCode: 'inline' },
    {
      label: 'Dirección hidraulica',
      value: controls.satIntegrationData.dirhidraulica,
      typeCode: 'inline',
    },
    {
      label: 'Análisis de gases',
      value: controls.satIntegrationData.analisisgases,
      typeCode: 'inline',
    },
  ];

  const description = `
    ESTADO GENERAL: \n
    PATENTE: ${controls.satIntegrationData.patente}\n
    KILOMETRAJE: ${controls.satIntegrationData.kilometraje}\n
    CAJA: ${controls.satIntegrationData.caja}\n
    MOTOR: ${controls.satIntegrationData.motor}\n
    COLOR: ${controls.satIntegrationData.color}\n
    AIRE: ${controls.satIntegrationData.aire}\n
    PERMISO DE CIRCULACION: ${controls.satIntegrationData.pcirluacion}\n
    SEGURO OBLIGATORIO: ${controls.satIntegrationData.seguroobl}\n
    REVISION TECNICA: ${controls.satIntegrationData.revisiontec}\n
    ANALISIS GASES: ${controls.satIntegrationData.analisisgases}\n
    `;

  return { lines, description };
};
